import React, { useState, useEffect, useReducer, useRef } from 'react';
import {withTheme, withStyles } from '@material-ui/core/styles';
import DeadEnd from '../../components/Generic/Page/DeadEnd';
import ProfileHeader from '../../components/UI/ProfileHeader/ProfileHeader3';
import ProfileLayout from '../../components/UI/ProfileLayoutNew/ProfileLayoutNew';
import { Typography, LinearProgress } from '@material-ui/core';
import TabBar from '../../components/UI/TabBar/TabBar';
import Body from '../../components/ColumnProfile/Body/Body';
import { handleShareClick, setInitialState,  getUserRoles, getDispFields, removeUrlQueryArg } from '../../utilities';
import ProfileSideBar from '../../components/UI/ProfileSideBar/ProfileSideBar';
import axiosCerebrum from '../../axios-cerebrum';
import { connect } from 'react-redux'
import * as actions from '../../store/actions/index';
import ProfileButton from '../../components/UI/Buttons/ProfileButton';
import axiosSolr from '../../axios-solr'
import DropdownButton from '../../components/UI/Buttons/DropdownButton'
import 'url-search-params-polyfill';
import {addHistory} from '../../HistoryManager'
import FollowButton from '../../components/UI/Buttons/FollowButton'
import { globalListenerRef } from '../../GlobalListenerRef';
import { checkLineageLinkable, checkProfileEditable, checkTermLinkable } from '../../permissionChecker';
import { lineageObjectsMap } from '../../components/UI/Lineage/utils';
import AddToCartButton from '../../components/UI/Buttons/AddToCartButton';
import { getAlterName } from '../../components/UI/ProfileHeader/utils';
import useAlert from '../../hooks/useAlert';
import ProfileModalController from '../../components/UI/ProfileModalController/ProfileModalController';

const styles = theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  modalRoot: {
    borderRadius: 4
  },
  normalText: {
    color: theme.palette.primaryText.main,
  }
});

const initialState = {
  tagModalOpen:false,
  tabState: 0,
  noteTabState:0,
  noteDetailMap:{},
  // basic table data
  basicData: null,

  insightsData: null,

  // usage
  usageData: null,
  usageError: null,
  usageLoading: null,

  usagePeriod:3,
  usageType:'direct',
  //panel uage data
  usagePanelData: null,
  usagePanelError: null,
  usagePanelLoading: null,
  // lienage
  lineageData:{
    tabState:0,
    upstreamView:'basic_map',
    downstreamView:'basic_map',
    upstreamObjects:['COLUMN','CONTENT','DATASET','DATA_PIPELINE','FILE','QUERY','DATASET_TABLE','DATASET_FIELD'],
    downstreamObjects:['COLUMN','CONTENT','DATASET','DATA_PIPELINE','FILE','QUERY','DATASET_TABLE','DATASET_FIELD'],
    
    upstreamListSort:'LAST_USED_DESC',
    downstreamListSort:'LAST_USED_DESC'
  },
  // lineageView:'recent',
  // lineageHistory:[],
  // lineageNumFound:0,
  //
  dqModalOpen: false,
  selectedDate: null,
  // reference
  referenceFilter:'name_srt asc',
  // joinsView:'map',
  relatedContentData: null,
};

function reducer(state, action) {
  switch (action.type) {
    case 'set_link_object_data':
      return {
        ...state,
        linkObjectData:action.linkObjectData
      }
    case 'set_list_modal_open':{
      return {
        ...state,
        listModalOpen:action.listModalOpen
      }
    }
    case 'set_terms':
      return {
        ...state,
        terms:action.terms,
        termsLoading:action.termsLoading,
        termsError:action.termsError
      }
    case 'set_notes':
      return {
        ...state,
        notesLoading:action.notesLoading,
        notesError:action.notesError,
        notes:action.notes
      }
    case 'set_survey_data':
      return {
        ...state,
        surveyData:action.surveyData,
        surveyLoading:action.surveyLoading,
        surveyError:action.surveyError
      }
    case 'set_note_tab_state':{
      return {
        ...state,
        noteTabState:action.noteTabState
      }
    }
    case 'set_note_detail_map':{
      return {
        ...state,
        noteDetailMap:action.noteDetailMap
      }
    }
    case 'set_note_modal_open':{
      return {
        ...state,
        noteModalOpen:action.noteModalOpen
      }
    }
    case 'set_tab_state':
      return {
        ...state,
        tabState: action.tabState,
      }
    case 'set_basic_data':
      return {
        ...state,
        basicData: action.basicData,
        basicDataError:action.basicDataError,
        basicDataLoading:action.basicDataLoading
      }
    case 'set_access_modal_open':{
      return {
        ...state,
        accessModalOpen:action.accessModalOpen
      }
    }
    case 'set_access_roles':
      return {
        ...state,
        accessRolesLoading:action.accessRolesLoading,
        accessRolesError:action.accessRolesError,
        accessRoles:action.accessRoles
      }
    case 'set_collection_instances':{
      return {
        ...state,
        collectionInstancesData:action.collectionInstancesData
      }
    }
    case 'set_editing_description':
      return {
        ...state,
        editingDescription: action.editingDescription
      }
    case 'set_insights_data':
      return {
        ...state,
        insightsData: action.insightsData,
      }
    case 'set_usage_data':
      return {
        ...state,
        usageData: action.usageData,
        usageError: action.usageError,
        usageLoading: action.usageLoading
      }
    case 'set_usage_active_series':
      return {
        ...state,
        usageActiveSeries: action.usageActiveSeries
      }
    case 'set_usage_panel_data':
      return {
        ...state,
        usagePanelData: action.usagePanelData,
        usagePanelError: action.usagePanelError,
        usagePanelLoading: action.usagePanelLoading
      }
    case 'set_usage_period':
      return {
        ...state,
        usagePeriod:action.usagePeriod
      }
    case 'set_usage_type':
      return {
        ...state,
        usageType:action.usageType
      }
    case 'set_lineage_data':
      return {
        ...state,lineageData:action.lineageData
      }
    case 'set_dqModal_open':
      return {
        ...state,
        dqModalOpen: action.dqModalOpen
      }
    case 'set_changes':
      return {
        ...state,
        changes:action.changes,
        changesLoading:action.changesLoading,
        changesError:action.changesError
      }
    case 'set_change_date':
      return {
        ...state,
        changeDate:action.changeDate
      }
    case 'set_change_type':
      return {
        ...state,
        changeType:action.changeType
      }
    case 'set_selected_change':
      return {
        ...state,
        selectedChange:action.selectedChange
      }
    case 'set_issue_data':
      return {
        ...state,
        issueData: action.issueData,
        issueError: action.issueError,
        issueLoading: action.issueLoading
      }
    case "set_selected_date":
      return {
        ...state,
        selectedDate: action.selectedDate
      }
    case 'set_related_data':
      return {
        ...state,
        relatedContentData: action.relatedContentData
      }
    case 'set_following':{
      return {
        ...state, following:action.following
      }
    }
    case 'set_dq_insights_data':
      return {
        ...state,
        dqInsightsData:action.dqInsightsData
      }
    // case 'set_quality_data':
    //   return {
    //     ...state,
    //     qualityData:action.qualityData,
    //     qualityError:action.qualityError,
    //     qualityLoading:action.qualityLoading
    //   }
    // case 'set_quality_date_data':
    //   return {
    //     ...state,
    //     qualityDateData:action.qualityDateData,
    //     qualityDateError:action.qualityDateError,
    //     qualityDateLoading:action.qualityDateLoading,
    //     qualityDateSelected: action.qualityDateSelected
    //   }
    // case 'set_dq_detail_data':
    //   return {
    //     ...state,
    //     dqDetailData:action.dqDetailData,
    //   }
    // case 'set_joins_data':
    //   return {
    //     ...state,
    //     joinsData:action.joinsData
    //   }
    // case 'set_joins_view':
    //   return {
    //     ...state,
    //     joinsView:action.joinsView
    //   }
    case 'set_additional_properties':
      return {
        ...state,
        additionalProperties:action.additionalProperties,
        additionalPropertiesLoading:action.additionalPropertiesLoading,
        additionalPropertiesError:action.additionalPropertiesError
      }
    case 'set_additional_properties_modal_open':
      return {
        ...state,
        additionalPropertiesModalOpen:action.additionalPropertiesModalOpen,
      }
    case 'set_is_steward_or_owner':{
      return {
        ...state, isStewardOrOwner:action.isStewardOrOwner
      }
    }
    default:
      throw new Error("Reducer action not supported.", action);
  }
}


const ColumnProfile = props => {
  const {
    match,
    theme,
    history,
    classes,
    sessionData,
    pageCache,
    storePageCache,
  } = props;


  const urlSearch = new URLSearchParams(window.location.search);
  const tabName = urlSearch.get('tabName');
  const roles = getUserRoles(sessionData.user_role)
  let startState = setInitialState(pageCache,initialState);

  const [state, dispatch] = useReducer(reducer, startState);
  const [linkModalOpen, setLinkModalOpen] = useState(false);

  let isLineageLinkable = checkLineageLinkable({sessionData, isStewardOrOwner: state.isStewardOrOwner})

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true
    } 
  },[])


  const getTabOptions = data => {
    // return ['DETAILS','KNOWLEDGE', roles.every(el=>el==='90') || data.usage_txt==='UNKNOWN'?undefined:'USAGE', 'LINEAGE','ISSUES','QUALITY','RELATED','CHANGES'].filter(el=>el!==undefined);
    return ['DETAILS','KNOWLEDGE', roles.every(el=>el==='90') || 'USAGE', 'LINEAGE','ISSUES','QUALITY','RELATED','CHANGES'].filter(el=>el!==undefined);
  }

  useEffect(()=>{
    if(!state)return;
    storePageCache({cacheID:window.location.href,...state})
  },[state,storePageCache])

  const fetchList = () => {
    dispatch({type:'set_basic_data',basicDataLoading:state.basicData?false:true,basicData:state.basicData});
    axiosCerebrum
      .get(
        `/api/columns/${match.params.id.toLowerCase()}`,
      )
      .then(async cerebrumResponse=>{
        let fullResponse = cerebrumResponse.data;
        if(!fullResponse){
          dispatch({ type: 'set_basic_data', basicDataError: true})
          return;
        }
        let sourceData;
        await axiosCerebrum.get(`/api/sources/${fullResponse.source_id}`).then(response=>sourceData=response.data).catch(error=>{console.log(error)})
        addHistory({url:window.location.pathname, title: getDispFields(fullResponse,'dispTitle'), subTitle:getDispFields(fullResponse,'dispSubtitle'),object:fullResponse,type:'profile'})
        axiosSolr
          .get(`/solr/search/select`,{params:{q:"*",fq:`id:${fullResponse.id}`,fl:'*'}})
          .then(response=>{
            dispatch({ type: 'set_basic_data', basicData: {...(state.basicData||{}),...(response.data.response.docs[0]||{}),...fullResponse, source_data:sourceData }})
          })
          .catch(error=>{
            dispatch({ type: 'set_basic_data', basicData: fullResponse})
          })
      })
      .catch(error=>{
        dispatch({ type: 'set_basic_data', basicDataError: true})
      })
  }

  const followDataFetch = () => {
    axiosCerebrum
      .get(`/api/me/follows/${match.params.id.toLowerCase()}?type=OPT_IN`)
      .then(response=>{
        if(response.data.type==='OPT_IN')dispatch({type:'set_following',following:true});
        else{dispatch({type:'set_following',following:false})}
      })
      .catch(error=>{
        dispatch({type:'set_following',following:false})
      })
  }

  useEffect(()=>{
    if(tabName && state.basicData){
      let tabOptions = getTabOptions(state.basicData);
      if(!tabOptions.includes(tabName.toUpperCase()))return;
      let presetTabState = tabOptions.indexOf(tabName.toUpperCase());
      dispatch({type:'set_tab_state',tabState:presetTabState})
      window.history.replaceState(null, null, removeUrlQueryArg({url:window.location.toString(),keys:['tabName']}));
    }
    // eslint-disable-next-line
  },[state.basicData])

  const issueFetchList = () => {
    dispatch({
      type: 'set_issue_data',
      issueLoading: true
    })
    axiosCerebrum
      .get(
        `/api/columns/${match.params.id.toLowerCase()}/related`,
        {params: {
          relationship:'IMPACTED_BY,IMPACTED_BY_SRC',
          per_page:200,
          sort:'END_DESC',
        }} 
      )
      .then(response=>{
        if(response.data.items.length===0){
          dispatch({
            type: 'set_issue_data',
            issueData: response.data
          })
          return;
        }
        axiosSolr
          .post('/solr/search/select',{query:'*',limit:response.data.items.length,filter:`id:(${response.data.items.map(el=>el.id).join(' OR ')})`})
          .then(solrRes=>{
            dispatch({
              type: 'set_issue_data',
              issueData: {...response.data,items:response.data.items.map(el=>solrRes.data.response.docs.find(i=>i.id===el.id)||el)},
            })
          })
          .catch(error=>{
            dispatch({
              type: 'set_issue_data',
              issueData: state.issueData,
              issueError: true,
            })
          })
      })
      .catch(error=>{
        dispatch({
          type: 'set_issue_data',
          issueData: state.issueData,
          issueError: true,
        })
      })
  }


  useEffect(()=>{
    if(!state.basicData){
      fetchList();
    }
    if(!state.issueData)issueFetchList();
    if(state.followData===undefined){
      followDataFetch()
    }
    // eslint-disable-next-line
  },[])

  const checkIsStewardOrOwner = () => {
    axiosCerebrum
      .get(`/api/users/${sessionData.id}/related`,{params:{
        object_id:match.params.id.toLowerCase(),
        relationship:'OWNER_OF,STEWARD_OF'
      }}) 
      .then(response=>{
        dispatch({type:'set_is_steward_or_owner', isStewardOrOwner:response.data.total>0})
      })
      .catch(error=>{
        console.log(error)
        dispatch({type:'set_is_steward_or_owner', isStewardOrOwner:false})
      })
  }

  useEffect(()=>{
    checkIsStewardOrOwner()
   // eslint-disable-next-line
  },[state.insightsData])

  useEffect(()=>{
    window.removeEventListener('message',globalListenerRef.profileLinkModalListener)
    globalListenerRef.profileLinkModalListener = (msg) => {
      if(msg.data.reload_related_terms ){
        dispatch({type:'set_terms'})
      }
      if(msg.data.reload_issue){
        issueFetchList()
      }
      if(msg.data.open_link_term_modal){
        setLinkModalOpen({relations:['RELATED'],linkableObjects:['TERM']})
      }
      if(!isLineageLinkable)return;
      if(msg.data.open_profile_link_modal ){
        setLinkModalOpen({
          relations:['SOURCE_OF','SOURCE_FROM'], 
          linkableObjects:lineageObjectsMap.COLUMN
        })
      }
    }
    window.addEventListener('message',globalListenerRef.profileLinkModalListener)

    return ()=>{
      window.removeEventListener('message',globalListenerRef.profileLinkModalListener)
    }

  // eslint-disable-next-line
  },[isLineageLinkable])

  useEffect(()=>{
    window.scrollTo(0,0)
  },[state.tabState])

  if (state.basicDataLoading) {
    return (
      <div style={{ textAlign:'center', width: '18.75rem',margin:'20vh auto'}}>
        <Typography className={classes.normalText}>Loading</Typography>
        <LinearProgress style={{ marginTop: '1.5rem' }} color="secondary" />
      </div>
    )
  }

  if (state.basicDataError ) {
    return (
      <DeadEnd />
    )
  }

  if(!state.basicData){
    return <div></div>
  }


  const data = state.basicData;
  const isShell = data.reference!==false;

  const handleFollowClick = () => {
    if(state.following){
      axiosCerebrum
        .delete(`/api/me/follows/${match.params.id.toLowerCase()}`)
        .then(()=>{
          sendAlert({message:'Stopped following this column',type:'info'})
          dispatch({type:'set_following',following:false})
        })
        .catch(error=>{
          console.log(error);
          sendAlert({message:'Error occurred unfollowing this column',type:'error'})
        })
    }else{
      axiosCerebrum
      .put(`/api/me/follows/${match.params.id.toLowerCase()}?type=OPT_IN`)
      .then(()=>{
        sendAlert({message:'Following this column',type:'info'})
        dispatch({type:'set_following',following:true})
      })
      .catch(error=>{
        console.log(error);
        sendAlert({message:'Error occurred following this column',type:'error'})
      })
    }
  }


  let buttons = [];

  buttons.push(
    <ProfileButton
      onClick={() => handleShareClick()}
      iconLabel='share'
      iconColour={theme.palette.primaryText.light}
      iconOnly={true}
      tooltip={'Share link'}
    />
  )


  let optionTitle = ['OPEN IN APPLICATIONS']
  let optionList = [
    roles.every(r=>r==='90') || state.basicData.active_flag===false?undefined:{onClick:() => history.push(`/impact_assessment?targetObjectId=${match.params.id.toLowerCase()}`),iconLabel:'ia_downstream',text:'Assess Downstream Impact',group:'OPEN IN APPLICATIONS'},
    roles.every(r=>r==='90') || state.basicData.active_flag===false?undefined:{onClick:() => history.push(`/dependency_assessment?targetObjectId=${match.params.id.toLowerCase()}`),iconLabel:'ia_upstream',text:'Assess Upstream Dependencies',group:'OPEN IN APPLICATIONS'}
  ]

  buttons.push(
    <DropdownButton
      iconLabel='dot_menu'
      tooltip="More actions"
      iconOnly={true}
      iconColour={theme.palette.primaryText.light}
      title={optionTitle}
      optionList={optionList}
    />
  )


  buttons.push(
    <ProfileButton
      onClick={() => dispatch({type:'set_list_modal_open',listModalOpen:true})}
      iconLabel='add_list'
      iconColour={theme.palette.primaryText.light}
      iconOnly={true}
      tooltip={'Add to List'}
    />
  )

  buttons.push(
    <AddToCartButton
      object={state.basicData}
    />
  )

  // if(!isShell){
    buttons.push(
      <DropdownButton
        iconLabel='add'
        text='ADD'
        optionList={[
          {onClick:() => dispatch({ type: 'set_dqModal_open', dqModalOpen: true }),iconLabel:'issue',text:'Add Issue'},
          {onClick:()=>dispatch({type:'set_note_modal_open',noteModalOpen:true}),iconLabel:'note',text:'Add Knowledge'},
          {onClick:()=>dispatch({type:'set_note_modal_open',noteModalOpen:{isQuestion:true}}),iconLabel:'question',text:'Ask Question'},
          checkTermLinkable({sessionData})?{onClick:()=>setLinkModalOpen({relations:['RELATED'],linkableObjects:['TERM']}),iconLabel:'glossary',text:'Add Term'}:undefined,
          checkProfileEditable({sessionData, isStewardOrOwner:state.isStewardOrOwner})?{onClick:()=>dispatch({type:'set_additional_properties_modal_open',additionalPropertiesModalOpen:true}),iconLabel:'info',text:'Add Property'}:undefined,
        ]}
      />
    )
  // }

  buttons.push(
    <FollowButton
      onClick={() => handleFollowClick()}
      following={state.following}
      object={state.basicData}
    />
  )

    
  const tabOptions = getTabOptions(data)

  let bannerdisplayText,bannerCause,bannerVisibility;

  let openIssueCount = state.issueData && state.issueData.items.filter(i=>!i.issue_status_txt || !i.issue_status_txt.includes('CLOSED')).length
  if (state.issueData && openIssueCount > 0) {
    bannerdisplayText = `This column has ${openIssueCount} open or in progress issue(s)`;
    bannerCause = 'issue';
    bannerVisibility =  'visible';
  }

  if(data && data.active_flag === false){
    bannerdisplayText = 'This column has been deleted';
    bannerCause = 'active';
    bannerVisibility = 'visible'
  }

  if (data && data.source_data && data.source_data.active_flag===false) {
    bannerdisplayText = 'The source of this column has been deactivated in K. Information on this column is dated and may no longer be accurate';
    bannerCause = 'active';
    bannerVisibility = 'visible'
  }
  if (data && isShell){
    let sourceName = data.source_data?data.source_data.name:'Unkown Source'
    if(data.reference_sources){
      if(data.reference_sources.length===0){
        bannerdisplayText=`This Column is referenced in unknown source. Limited metadata available.`
      }
      else if(data.reference_sources.length===1){
        bannerdisplayText=`This Column is referenced in ${data.reference_sources[0].reference_source.name}. ${sourceName} is not connected to K. Limited metadata available.`
      }else{
        bannerdisplayText=(
          <Typography>
            This Column is referenced in <span onClick={()=>dispatch({type:'set_tab_state',tabState:getTabOptions(state.basicData).indexOf('RELATED')})} style={{textDecoration:'underline',cursor:'pointer'}}>multiple Tools</span>. {sourceName} is not connected to K. Limited metadata available.
          </Typography>
        )
      }
    }
    else{
      bannerdisplayText= 'This Column is referenced in content but not integrated as a source. Limited metadata available.';
    }
    bannerCause = 'shell';
    bannerVisibility = 'visible'
  }

  // let title = state.basicData.name;
  // let description;
  // if(state.basicData.alternate_name && state.basicData.alternate_name.trim()!==''){
  //   if(!roles.every(el=>el==='90')){
  //     description = `Business name: ${state.basicData.alternate_name}`
  //   }else{
  //     title = state.basicData.alternate_name
  //     description = `Technical name: ${state.basicData.name}`
  //   }
  // }

  let {title, description} = getAlterName(state.basicData, roles)

  return (
    <div>
      <ProfileModalController
        state={state}
        dispatch={dispatch}
        linkModalOpen={linkModalOpen}
        setLinkModalOpen={setLinkModalOpen}
        modalMapping={['list','link','note','additional_property','issue']}
        history={history}
      />
      <ProfileLayout
        header={
          <div>
            <ProfileHeader
              tabOptions={tabOptions}
              type='column'
              title={title}
              description={description}
              shouldLoadBreadCrumb
              label='column'
              buttons={buttons}
              data={state.basicData}
              history={history}
              isShell={isShell}
              state={state}
              dispatch={dispatch}
              bannerdisplayText={bannerdisplayText}
              bannerVisibility={bannerVisibility}
              bannerCause={bannerCause}
              shouldLoadLinkedInstance
              onClickAddTag={()=>{
                setLinkModalOpen({
                  linkableObjects:['COLLECTION_INSTANCE'],
                  relations:['MEMBER_OF'],
                  onLinkUpdated:()=>{
                    window.postMessage({reload_header_instance:true},document.location.protocol + "//" + document.location.hostname+':'+document.location.port)
                  }
                })
              }}
            />
          </div>}
        tabBar={
          <TabBar
            tabOptions={tabOptions}
            tabState={state.tabState}
            setTabState={value => dispatch({ type: 'set_tab_state', tabState: value })}
            minWidth={170}
            maxWidth={200}
            disableUnderline={true}
          />
        }
        body={
          <Body
            history={history}
            nodeKey={state.basicData.nodeKey}
            label={data.labels}
            tabState={state.tabState}
            fetchList={fetchList}
            data={data}
            state={state}
            dispatch={dispatch}
            sessionData={sessionData}
            setLinkModalOpen={setLinkModalOpen}
            tabOptions={tabOptions}
            isLineageLinkable={isLineageLinkable}
          />
        }
        disableMinContentHeight={['CHANGES','LINEAGE'].includes(tabOptions[state.tabState])}
        hideSideBar={['LINEAGE','JOINS','CHANGES','RELATED','QUALITY'].includes(tabOptions[state.tabState])}
        hideHeader={['LINEAGE'].includes(tabOptions[state.tabState])}
        sideBar={
          <ProfileSideBar
            tabOptions={tabOptions}
            history={history}
            state={state}
            dispatch={dispatch}
            basicData={state.basicData}
            sessionData={sessionData}
            fetchList={fetchList}
            cerebrumLabel={'columns'}
            data={state.basicData}
            mapping={
              ['tags','masked','piiDet','category','description','sourceType','dataType','domain','verified','verifiedNot', 'trustscore','knowledge','classification','channels', 'owner', 'stewardedBy','lineage','upstreamSources','downstreamSources','topUsers', 'lastUsed','lastUpdated', 'createdBy']
            }
          />
        }
      />
    </div>)
}

const mapStateToProps = state => {
  return {
    pageCache: state.pageCache.pageCache,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    storePageCache: (state) => dispatch(actions.storePageCache(state))
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(withTheme()(withStyles(styles)(ColumnProfile)));