import { Badge, createStyles, withStyles } from "@material-ui/core";
import { getIconComponent } from "../../../utilities";
import KTooltip from "../KTooltip/KTooltip";

export const getIconLabel = ({label, item}) => {
  if(!item)return label;
  if(item.source_template){
    if(getIconComponent({label:item.source_template.name})){
      return item.source_template.name
    }else{
      return 'source'
    }
  }
  if(item.object?.name==='HOST')return 'host'
  if(!label)return '';
  let iconLabel = label;
  if(label.toLowerCase()==='collection'){
    let collectionName = (item.name_txt || item.name || '').toLowerCase()
    let collectionCat = (item.category || item.collection_type_txt || '').toLowerCase()
    if(collectionCat==='glossary')iconLabel = 'glossary'
    if(collectionName==='domain')iconLabel = 'domain'
    if(collectionName==='verified use case')iconLabel = 'verified'
    if(collectionName==='list')iconLabel = 'list'
    if(collectionName==='access role')iconLabel = 'access_role'
    if(collectionName==='classification')iconLabel = 'classification'
    if(collectionName==='question')iconLabel = 'question'
    if(collectionName==='job type')iconLabel = 'job_type'
    if(collectionCat==='knowledge'){
      if(collectionName==='question'){
        iconLabel = 'question'
      }
      else if(collectionName==='business logic'){
        iconLabel = 'business_logic_note'
      }
      else if(collectionName==='decision'){
        iconLabel = 'decision_note'
      }
      else if(collectionName==='link'){
        iconLabel = 'link_note'
      }
      else if(collectionName==='how to'){
        iconLabel = 'how_to_note'
      }
      else if(collectionName==='note'){
        iconLabel = 'note_note'
      }
      else if(collectionName==='roadmap'){
        iconLabel = 'roadmap_note'
      }
      else if(collectionCat==='knowledge'){
        iconLabel = 'note'
      }
      else{iconLabel='note'}
    }
  }
  if (label.toLowerCase()==='collection_instance') {
    let collectionName;
    let collectionCat;
    if(item.collection_name){
      collectionName = item.collection_name
    }
    if(item.collection_txt){
      collectionName = item.collection_txt
    }
    if(item.parent){
      collectionName = item.parent.name
      collectionCat = item.parent.category
    }
    if(item.collection_category){
      collectionCat = item.collection_category
    }
    if(item.collection_type_txt){
      collectionCat = item.collection_type_txt
    }
    if(collectionName)collectionName = collectionName.toLowerCase()
    if(collectionCat)collectionCat = collectionCat.toLowerCase()
    if (collectionName=== 'location'){ 
      iconLabel = 'location' 
    }
    else if(collectionName==='question'){
      iconLabel = 'question'
    }
    else if(collectionName==='business logic'){
      iconLabel = 'business_logic_note'
    }
    else if(collectionName==='decision'){
      iconLabel = 'decision_note'
    }
    else if(collectionName==='link'){
      iconLabel = 'link_note'
    }
    else if(collectionName==='how to'){
      iconLabel = 'how_to_note'
    }
    else if(collectionName==='note'){
      iconLabel = 'note_note'
    }
    else if(collectionName==='roadmap'){
      iconLabel = 'roadmap_note'
    }
    else if(collectionCat==='knowledge'){
      iconLabel = 'note'
    }
    else if(collectionName==='list'){
      iconLabel = 'list'
    }
    else if(collectionName==='channel'){
      iconLabel = 'channel'
    }
    else if(collectionName==='classification'){
      iconLabel = 'classification'
    }
    else if(collectionName==='verified use case'){
      iconLabel = 'verified'
    }
    else if(collectionName==='job type'){
      iconLabel = 'job_type'
    }
    else if(collectionName==='domain'){
      iconLabel = 'domain'
    }
    else if(collectionName==='access role'){
      iconLabel = 'access_role'
    }
    else if(collectionCat==='change'){
      iconLabel = 'change'
    }
    else if(collectionCat==='glossary'){
      iconLabel = 'term'
    }
    else { iconLabel = 'collections' }
  }
  if(label.toLowerCase()==='table'){
    if(item.table_type_txt==='VIEW'){
      iconLabel = 'table_view'
    }
    else if(item.table_type_txt==='TABLE'){
      iconLabel = 'table_table'
    }
    else if(item.table_type_txt==='STREAM'){
      iconLabel = 'table_stream'
    }
    else if(item.table_type_txt==='SYNONYM'){
      iconLabel = 'table_synonym'
    }
    else if(item.table_type_txt==='MATERIALISED_VIEW'){
      iconLabel = 'table_materialised_view'
    }


  }
  return iconLabel
}

export const getDomainText = domains => {
  let text = '';
  if(domains.length===1)text = domains[0];
  if(domains.length===2)text = domains[0] + ' & ' + domains[1]
  if(domains.length===3)text = domains[0] + ', ' + domains[1] + ' & ' + domains[2]
  if(domains.length>3)text = domains.join(', ')+'...'
  return text;
}

const DeletedBadge = withStyles((theme) =>
  createStyles({
    badge: {
      padding: '0 0px',
      width: '16px',
      height: '16px',
      minWidth: '16px',
      borderRadius:8,
      backgroundColor: '#E01F21',
      color: '#fff',
    },
  }),
  )(Badge);

const AddedBadge = withStyles((theme) =>
  createStyles({
    badge: {
      padding: '0 0px',
      width: '16px',
      height: '16px',
      minWidth: '16px',
      borderRadius:8,
      backgroundColor: '#00D46A',
      color: '#fff',
    },
  }),
)(Badge);

const ModifiedBadge = withStyles((theme) =>
  createStyles({
    badge: {
      padding: '0 0px',
      width: '16px',
      height: '16px',
      minWidth: '16px',
      borderRadius:8,
      backgroundColor: '#F76A1C',
      color: '#fff',
    },
  }),
)(Badge);

export const addBadgeToIcon = ({icon, modification_badge, active, opacity}) => {
  if(modification_badge==='DELETED' || active==='NO' || active===false){
    return <KTooltip placement="top-start" title={'Deleted'}><DeletedBadge style={{opacity}} badgeContent={'x'}>{icon}</DeletedBadge></KTooltip> 
  }
  if(modification_badge==='NEW'){
    return <KTooltip placement="top-start" title={'New'}><AddedBadge  style={{opacity}} badgeContent={'+'}>{icon}</AddedBadge></KTooltip>
  }
  if(modification_badge==='MODIFIED'){
    return <KTooltip placement="top-start" title={'Modified'}><ModifiedBadge style={{opacity}} badgeContent={'!'}>{icon}</ModifiedBadge></KTooltip>
  }
  return icon;
}

export const isShowTrust = (objecType) => {
  return ['dataset','data_pipeline','schema','table', 'column', 'tableau', 'content','content_child','file','report','sheet','content_app'].includes(objecType.toLowerCase())
}