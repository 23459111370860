import React, { useState,  useEffect, useReducer, useRef } from 'react';
import {withTheme, withStyles } from '@material-ui/core/styles';
import DeadEnd from '../../components/Generic/Page/DeadEnd';
import ProfileHeader from '../../components/UI/ProfileHeader/ProfileHeader3';
import ProfileLayout from '../../components/UI/ProfileLayoutNew/ProfileLayoutNew';
import { Typography, LinearProgress } from '@material-ui/core';
import { handleShareClick, setInitialState, getDispFields, getUserRoles } from '../../utilities';
import axiosCerebrum from '../../axios-cerebrum';
import { connect } from 'react-redux'
import * as actions from '../../store/actions/index';
import ProfileButton from '../../components/UI/Buttons/ProfileButton';
import axiosSolr from '../../axios-solr'
import 'url-search-params-polyfill';
import {addHistory} from '../../HistoryManager'
import FollowButton from '../../components/UI/Buttons/FollowButton'
import Body from '../../components/DQTestProfile/Body/Body'
import ProfileSideBar from '../../components/UI/ProfileSideBar/ProfileSideBar';
import { getAlterName } from '../../components/UI/ProfileHeader/utils';
import useAlert from '../../hooks/useAlert';
import ProfileModalController from '../../components/UI/ProfileModalController/ProfileModalController';
import AddToCartButton from '../../components/UI/Buttons/AddToCartButton';
// import { checkProfileEditable } from '../../permissionChecker';
// import useGetCerebrum from '../../hooks/useGetCerebrum';

const styles = theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  modalRoot: {
    borderRadius: 4
  },
  normalText: {
    color: theme.palette.primaryText.main,
  }
});

const initialState = {
  linkedSort:'object_name_srt asc',
  resultDataCache:{},
  chartPeriod:30,
};

function reducer(state, action) {
  switch (action.type) {
    case 'set_basic_data':
      return {
        ...state,
        basicData: action.basicData,
        basicDataError:action.basicDataError,
        basicDataLoading:action.basicDataLoading
      }
    case 'set_insights_data':
      return {
        ...state,
        insightsData: action.insightsData,
      }
    case 'set_following':{
      return {
        ...state, following:action.following
      }
    }
    case 'set_collection_instances':
      return {
        ...state,
        collectionInstancesData:action.collectionInstancesData
      }   
    case 'set_selected_record':
      return {
        ...state,
        selectedRecord:action.selectedRecord
      } 
    case 'set_linked_sort':
      return {
        ...state,
        linkedSort:action.linkedSort
      }
    case 'set_chart_period':
      return {
        ...state,
        chartPeriod:action.chartPeriod
      }
    case 'set_result_data_cache':
      state.resultDataCache = action.resultDataCache;
      return state;
    case 'set_additional_properties':
      return {
        ...state,
        additionalProperties:action.additionalProperties,
        additionalPropertiesLoading:action.additionalPropertiesLoading,
        additionalPropertiesError:action.additionalPropertiesError
      }
    case 'set_linked_to_data':
      return {
        ...state,
        linkedToData:action.linkedToData,
        linkedToLoading:action.linkedToLoading,
        linkedToError:action.linkedToError
      }
    default:
      throw new Error("Reducer action not supported.", action);
  }
}


const DQTestProfile = props => {
  const {
    match,
    theme,
    history,
    classes,
    pageCache,
    storePageCache,
    sessionData
  } = props;

  let startState = setInitialState(pageCache,initialState);
  const [state, dispatch] = useReducer(reducer, startState);
  const roles = getUserRoles(sessionData.user_role)

  const [linkModalOpen, setLinkModalOpen] = useState(false);

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true
    } 
  },[])


  useEffect(()=>{
    if(!state)return;
    storePageCache({cacheID:window.location.href,...state})
  },[state,storePageCache])

  const fetchList = () => {
    dispatch({type:'set_basic_data',basicDataLoading:state.basicData?false:true,basicData:state.basicData});
    axiosCerebrum
      .get(
        `/api/dataqualitytests/${match.params.id.toLowerCase()}`,
      )
      .then(cerebrumResponse=>{
        let fullResponse = cerebrumResponse.data;
        if(!fullResponse){
          dispatch({ type: 'set_basic_data', basicDataError: true})
          return;
        }
        addHistory({url:window.location.pathname, title: getDispFields(fullResponse,'dispTitle'), subTitle:getDispFields(fullResponse,'dispSubtitle'),object:fullResponse,type:'profile'})
        axiosSolr
          .get(`/solr/search/select`,{params:{q:"*",fq:`id:${fullResponse.id}`,fl:'source_txt,source_id_srt, source_type_txt'}})
          .then(response=>{
            dispatch({ type: 'set_basic_data', basicData: {...(state.basicData||{}),...fullResponse,...(response.data.response.docs[0]||{})} })
          })
          .catch(error=>{
            dispatch({ type: 'set_basic_data', basicData: fullResponse})
          })
      })
      .catch(error=>{
        dispatch({ type: 'set_basic_data', basicDataError: true})
      })
  }

  useEffect(()=>{
    if(!state.basicData)fetchList();
    // eslint-disable-next-line
  },[])


  if (state.basicDataLoading) {
    return (
      <div style={{ textAlign:'center', width: '18.75rem',margin:'20vh auto'}}>
        <Typography className={classes.normalText}>Loading</Typography>
        <LinearProgress style={{ marginTop: '1.5rem' }} color="secondary" />
      </div>
    )
  }

  if (state.basicDataError ) {
    return (
      <DeadEnd />
    )
  }

  if(!state.basicData){
    return <div></div>
  }


  const handleFollowClick = () => {
    if(state.following){
      axiosCerebrum
        .delete(`/api/me/follows/${match.params.id.toLowerCase()}`)
        .then(()=>{
          sendAlert({message:"Stopped following this data quality test",type:'info'})
          dispatch({type:'set_following',following:false})
        })
        .catch(error=>{
          console.log(error);
          sendAlert({message:"Error occurred unfollowing this data quality test",type:'error'})
        })
    }else{
      axiosCerebrum
      .put(`/api/me/follows/${match.params.id.toLowerCase()}?type=OPT_IN`)
      .then(()=>{
        sendAlert({message:"Following this data quality test",type:'info'})
        dispatch({type:'set_following',following:true})
      })
      .catch(error=>{
        console.log(error);
        sendAlert({message:"Error occurred following this data quality test",type:'error'})
      })
    }
  }


  let buttons = [];

  buttons.push(
    <ProfileButton
      onClick={() => handleShareClick()}
      iconLabel='share'
      iconColour={theme.palette.primaryText.light}
      iconOnly={true}
      tooltip={'Share link'}
    />
  )

  buttons.push(
    <AddToCartButton
      object={state.basicData}
    />
  )

  // let isEditable = checkProfileEditable({sessionData})

  // if(isEditable){
  //   buttons.push(
  //     <ProfileButton
  //       onClick={() => setEditing(true)}
  //       iconLabel='edit'
  //       text='EDIT'
  //     />
  //   );
  // }


  buttons.push(
    <FollowButton
      onClick={() => handleFollowClick()}
      following={state.following}
      object={state.basicData}
    />
  )

  let {title, description} = getAlterName(state.basicData, roles)

  return (
    <div>
      <ProfileModalController
        state={state}
        dispatch={dispatch}
        linkModalOpen={linkModalOpen}
        setLinkModalOpen={setLinkModalOpen}
        modalMapping={['link']}
        history={history}
      />
      <ProfileLayout
        header={
          <ProfileHeader
            type='data_quality_test'
            title={title}
            description={description}
            // subtitle={breadcrumbs}
            label='data_quality_test'
            buttons={buttons}
            data={state.basicData}
            history={history}
            state={state}
            shouldLoadBreadCrumb
            dispatch={dispatch}
            shouldLoadLinkedInstance
            onClickAddTag={()=>{
              setLinkModalOpen({
                linkableObjects:['COLLECTION_INSTANCE'],
                relations:['MEMBER_OF'],
                onLinkUpdated:()=>window.postMessage({reload_header_instance:true},document.location.protocol + "//" + document.location.hostname+':'+document.location.port)
              })
            }}
          />
        }
        body={
          <Body
            history={history}
            state={state}
            dispatch={dispatch}
            fetchList={fetchList}
          />
        }
        sideBar={
          <ProfileSideBar
            history={history}
            state={state}
            dispatch={dispatch}
            sessionData={sessionData}
            fetchList={fetchList}
            cerebrumLabel={'dataqualitytests'}
            data={state.basicData}
            mapping={['sourceType','dq_dimension','lastUpdated']}
          />
        }
      />
    </div>)
}

const mapStateToProps = state => {
  return {
    pageCache: state.pageCache.pageCache,
    permissions: state.auth.permissions
  };
}

const mapDispatchToProps = dispatch => {
  return {
    storePageCache: (state) => dispatch(actions.storePageCache(state))
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(withTheme()(withStyles(styles)(DQTestProfile)));