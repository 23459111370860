import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withTheme, withStyles, Popper, ClickAwayListener } from '@material-ui/core';
import { globalListenerRef } from '../../../../GlobalListenerRef';
import KTooltip from '../../../UI/KTooltip/KTooltip';
import { collectionIds, getIconComponent, sendMessage } from '../../../../utilities';
import NodeSearch from './NodeSearch';
import { checkIsDataGov, checkIsDataManager } from '../../../../permissionChecker';
import { checkClassName } from '../../../UI/Editor/utils';

const styles = theme => ({
  root:{
    position:'fixed',
    width:48,
    borderRadius:4,
    // add shadow on all sides
    boxShadow: '0px 0px 8px 0px rgba(0,0,0,0.2)',
    transition:'max-height 0.3s',
    overflow:'hidden',
    zIndex:99,
    background:theme.palette.background.main,
    border:`1px solid ${theme.palette.border.main}`,
  },
  expandTrigger:{
    width:48,
    height:48,
    flexShrink:0,
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
  },
  controlRoot:{
    width:48,
    height:48,
    borderRadius:3,
    flexShrink:0,
    cursor:'pointer',
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    '&:hover':{
      background:theme.palette.hovered.main,
    }
  },
  shortcutWrapper:{
    width:'max-content',
    height:24,
    background:'#E0E0E0',
    color:'#000',
    padding:'0px 4px',
    borderRadius:6,
    fontSize:13.75,
    display:'flex',
    alignItems:'center',
    justifyContent:'center'
  },
})

function MainControls(props) {
  const {
    classes,
    theme,
    // history,
    sessionData,
    onRestore,
    nodes
  } = props;

  const [left, setLeft] = useState(120);
  const [top, setTop] = useState(130);
  const [visible, setVisible] = useState(false)
  // const [expanded, setExpanded] = useState(false)
  const [anchor, setAnchor] = useState()
  const [selectedControl, setSelectedControl] = useState()
  

  const getPos = () => {
    let offset = 0;
    if(window.innerWidth>2560){
      offset = -72
    }
    setLeft((document.getElementById('profile-content')?.getBoundingClientRect().left || 180) + offset)
    setTimeout(()=>{
      setTop((document.getElementById('profile-content')?.getBoundingClientRect().top || 73) + 60)
    },500)
  }

  useEffect(()=>{
    setTimeout(()=>{
      setVisible(true)
    },500)

    getPos()

    window.removeEventListener('resize',globalListenerRef.mapResizeListener)
    globalListenerRef.mapResizeListener = () => {
      getPos()
    }
    window.addEventListener('resize',globalListenerRef.mapResizeListener)
    return ()=>window.removeEventListener('resize',globalListenerRef.mapResizeListener)
  },[])

  useEffect(()=>{

    window.removeEventListener('message',globalListenerRef.mapControlMsgListener)
    globalListenerRef.mapControlMsgListener = (msg) => {
      if(msg.data.close_control_drawer){
        setAnchor()
        setSelectedControl()
      }
    }
    window.addEventListener('message',globalListenerRef.mapControlMsgListener)
    return ()=>window.removeEventListener('message',globalListenerRef.mapControlMsgListener)
  },[])


  if(!visible) return null

  const controls = [
    {
      iconLabel:'search',
      tooltip:'search on map',
      id:'search'
    },
    {
      iconLabel:'recenter',
      tooltip:'recenter map',
      id:'receter',
      onClick:()=>{sendMessage({'react-flow-recenter':true})}
    },
    {
      iconLabel:'refresh',
      tooltip:'reload map',
      id:'reload',
      onClick:onRestore
    },
    {
      iconLabel:'keyboard',
      tooltip:(
        <div style={{padding:16}}>
          <div style={{display:'flex',alignItems:'center'}}>
            <div className={classes.shortcutWrapper}>Alt</div>
            <span style={{marginLeft:8,marginRight:8}}>/</span>
            <div className={classes.shortcutWrapper}>Option</div>
            <span style={{marginLeft:8}}>Hold to enable layer edit</span>
          </div>      
        </div>
      ),
      id:'shortcut'
    },
    (checkIsDataGov({sessionData}) || checkIsDataManager({sessionData})) ? 
    {
      iconLabel:'add_layer',
      tooltip:'Add or manage Layers',
      id:'add_layer',
      onClick:()=>window.open(`/profile/collection/${collectionIds.sourceLayer}`,'_blank')
    }:undefined
  ].filter(el=>el)

  return (
    <div 
      className={classes.root}
      style={{
        top,
        left,
        // maxHeight:expanded?'100vh':48,
        maxHeight:'100vh',
        minHeight:48,
      }}
    >
      {
        controls.map(c=>{
          return (
            <KTooltip title={c.tooltip} placement={'right-start'}>
              <div 
                className={classes.controlRoot + ' map-control-button'}
                style={{
                  background:c.disabled?theme.palette.background.main:(c.isFocused?theme.palette.primary.dark:(selectedControl===c.id?theme.palette.hovered.main:undefined)),
                  cursor:c.disabled?'default':undefined,
                }}
                id={c.buttonId}
                onClick={e=>{
                  if(c.disabled)return;
                  if(c.onClick){
                    c.onClick()
                    setAnchor()
                    setSelectedControl()
                    return;
                  }
                  if(selectedControl===c.id){
                    setAnchor()
                    setSelectedControl()
                    return;
                  }
                  setAnchor(e.currentTarget)
                  setSelectedControl(c.id)
                }}
              >
                {
                  getIconComponent({
                    label:c.iconLabel,
                    size:24,
                    colour:c.isFocused?theme.palette.background.main:theme.palette.primaryText.light,
                  })
                }
              </div>
            </KTooltip>
          )
        })
      }

      <Popper style={{zIndex:1290}} open={anchor} anchorEl={anchor} placement='right-start'>
        <ClickAwayListener 
          onClickAway={(event)=>{
            if(checkClassName(event.target,'map-control-button'))return
            setAnchor()
            setSelectedControl()
          }}
        >
          <div>
            {
              selectedControl==='search' && 
              <NodeSearch
                nodes={nodes}
              />
            }
          </div>
        </ClickAwayListener>
      </Popper>
    </div>
  )
}

MainControls.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  nodes: PropTypes.array,
  onRestore: PropTypes.func,
}

export default withTheme()(withStyles(styles)(MainControls));