import React, { useEffect, useReducer, useRef, useState } from 'react';
import {withTheme, withStyles } from '@material-ui/core/styles';
import DeadEnd from '../../components/Generic/Page/DeadEnd';
import { Typography, LinearProgress } from '@material-ui/core';
import { headerAutoCollectionList, getLabelPlural, mapSearchObjectName, sendMessage } from '../../utilities';
import axiosCerebrum from '../../axios-cerebrum';
import { connect } from 'react-redux'
import * as actions from '../../store/actions/index';
import axiosSolr from '../../axios-solr'
import MiniProfileHeader from '../../components/UI/ProfileHeader/MiniProfileHeader';
import Body from '../../components/MiniProfile/Body/Body'
import {useStore} from 'react-redux'
import { globalListenerRef } from '../../GlobalListenerRef'
import GenericLinkModal from '../../components/UI/GenericLinkModal/GenericLinkModal';
import { checkCanAddKnowledge, checkLineageLinkable } from '../../permissionChecker';
import { getLinkableObjectsByLevel, lineageObjectsMap } from '../../components/UI/Lineage/utils';
import MiniVerticalTabBar from '../../components/UI/VerticalTabBar/MiniVerticalTabBar';
import NoteAdder from '../../components/UI/NoteAdder/NoteAdder';

const styles = theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  modalRoot: {
    borderRadius: 4
  },
  underlineOnHover: {
    ...theme.components.breadcrumb
  },
  normalText: {
    color: theme.palette.primaryText.main,
  },

  customScroll:{
    ...theme.components.hideScroll
  },
});

const initialState = {
  tabState: 0,
  relatedSelectedType:'none',
  relatedListData:{},
  relatedSearch:{},
  relatedShowRef:{},
  childSearch:'',
  tagView:'trust',
  noteDetailMap:{}
};

function reducer(state, action) {
  switch (action.type) {
    case 'set_basic_data':
      return {
        ...state,
        basicData: action.basicData,
        basicDataError:action.basicDataError,
        basicDataLoading:action.basicDataLoading
      }
    case 'set_tab_state':
      return {
        ...state,
        tabState: action.tabState,
      }
    ////////////////
    case 'set_tag_view':
      return {
        ...state,
        tagView:action.tagView
      }
    case 'set_child_list_data':
      return {
        ...state,
        childListData: action.childListData,
        childListLoading:action.childListLoading,
        childListError:action.childListError
      }
    case 'set_reference_child_count':
      return {
        ...state,
        referenceChildCount:action.referenceChildCount
      }
    case 'set_show_reference_child':
      return {
        ...state,
        showReferenceChild:action.showReferenceChild
      }
    case 'set_show_deleted_child':
      return {
        ...state,
        showDeletedChild:action.showDeletedChild
      }
    case 'set_child_search':
      return {
        ...state,
        childSearch:action.childSearch
      }
    ///////////////
    case 'set_related_object_types':
      return {
        ...state,
        relatedObjectTypes: action.relatedObjectTypes,
        relatedObjectTypesLoading:action.relatedObjectTypesLoading,
        relatedObjectTypesError:action.relatedObjectTypesError
      }
    case 'set_related_selected_type':
      return {
        ...state,
        relatedSelectedType: action.relatedSelectedType,
      }
    case 'set_related_show_ref':
      return {
        ...state,
        relatedShowRef: action.relatedShowRef,
      }
    case 'set_related_list_data':
      return {
        ...state,
        relatedListData: action.relatedListData,
      }
    case 'set_related_search':
      return {
        ...state,
        relatedSearch: action.relatedSearch,
      }
    case 'set_notes':
      return {
        ...state,
        notes: action.notes,
        notesLoading:action.notesLoading,
        notesError:action.notesError
      }
    case 'set_survey_data':
      return {
        ...state,
        surveyData:action.surveyData,
        surveyLoading:action.surveyLoading,
        surveyError:action.surveyError
      }
    case 'set_note_modal_open':{
      return {
        ...state,
        noteModalOpen:action.noteModalOpen
      }
    }
    case 'set_note_detail_map':{
      return {
        ...state,
        noteDetailMap:action.noteDetailMap
      }
    }
    //////////////
    case 'set_stats_data':
      return {
        ...state,
        statsData: action.statsData,
      }
    case 'set_is_steward_or_owner':{
      return {
        ...state, isStewardOrOwner:action.isStewardOrOwner
      }
    }
    case 'set_collection_instances':{
      return {
        ...state,
        collectionInstancesData:action.collectionInstancesData
      }
    }
    case 'set_insights_data':{
      return {
        ...state,
        insightsData: action.insightsData,
      }
    }
    case 'set_side_bar_data':{
      return {
        ...state,
        sideBarData:action.sideBarData,
        sideBarLoading:action.sideBarLoading,
        sideBarError:action.sideBarError
      }
    }
    case 'set_following':{
      return {
        ...state, following:action.following
      }
    }
    case 'set_user_map':{
      return {
        ...state,
        userMap:action.userMap
      }
    }
    case 'set_collection_map':{
      return {
        ...state,
        collectionMap:action.collectionMap
      }
    }
    case 'set_editing':{
      return {
        ...state,
        editing:action.editing
      }
    }
    case 'set_link_object_data':
      return {
        ...state,
        linkObjectData:action.linkObjectData
      }
    default:
      console.log(action.type)
      throw new Error("Reducer action not supported.", action);
  }
}

const MiniProfile = props => {
  const {
    history,
    classes,
    theme,
    id,
    customButtons,
    tagView,
    generateCustomBody
  } = props;

  const store = useStore();
  const sessionData = store.getState().auth.session_user;

  const [state, dispatch] = useReducer(reducer, initialState);
  const [linkModalOpen, setLinkModalOpen] = useState(false);

  const [scrollYOffset, setScrollYOffest] = useState(0);
  const scrollContainerRef = useRef()


  let isLineageLinkable = checkLineageLinkable({sessionData, isStewardOrOwner: state.isStewardOrOwner})

  const fetchList = () => {
    dispatch({type:'set_basic_data',basicDataLoading:state.basicData?false:true,basicData:state.basicData});
    axiosSolr
      .get(`/solr/search/select`,{params:{q:"*",fq:`id:${id}`}})
      .then(response=>{
        let solrResp = response.data.response.docs[0]
        
        axiosCerebrum
          .get(
            `/api/${getLabelPlural(mapSearchObjectName(solrResp.object_type_txt,solrResp.code_type_txt))}/${id}`,
            {params:{
              per_page:1
            }}
          )
          .then(cerebrumResp=>{
            dispatch({ type: 'set_basic_data', basicData: {...solrResp,...cerebrumResp.data} })
          })
          .catch(error=>{
            console.log(error)
            dispatch({ type: 'set_basic_data', basicDataError:true})
          })
      })
      .catch(error=>{
        console.log(error)
        dispatch({ type: 'set_basic_data', basicDataError:true})
      })
  }

  const collectionInstancesFetch = () => {
    const loadInstances = async () => {
      let autoRes = []
      await axiosCerebrum
        .get(
          '/api/stats',
          {params:{
            object_ids:state.basicData.id
          }}
        )
        .then(response=>{
          let item = response.data.items[0];
          headerAutoCollectionList.forEach(el=>{
            if(item[el]===null || !item[el])return;
            autoRes.push({
              name:item[el],
              parent_name:el,
              isAuto:true
            })
          })
        })
      let manualRes = await axiosCerebrum
        .get(
          `/api/${getLabelPlural(mapSearchObjectName(state.basicData.object_type_txt,state.basicData.code_type_txt))}/${id}/related/collections`,
          {params:{
            category:'DATA_MANAGEMENT,DATA_GOVERNANCE,PLATFORM',
            relationship:'MEMBER_OF',
            per_page:200
          }}
        );
      let finalRes = {
        total:autoRes.length+(manualRes?manualRes.data.total:0),
        items:[...autoRes,...(manualRes?manualRes.data.items:[])]
      }
      // window.postMessage({reload_link_modal:'true'},document.location.protocol + "//" + document.location.hostname+':'+document.location.port)
      dispatch({
        type:'set_collection_instances',
        collectionInstancesData:finalRes
      })
    }
    loadInstances()
  }

  const statsDataFetch = () => {
    axiosCerebrum
      .get(
        `/api/stats?object_ids=${id}`
      )
      .then(response=>{
        dispatch({
          type:'set_stats_data',
          statsData:response.data.items[0]
        })
      })
      .catch(error=>{
        console.log(error);
      })
  }

  const followDataFetch = () => {
    axiosCerebrum
      .get(`/api/me/follows/${state.basicData.id.toLowerCase()}?type=OPT_IN`)
      .then(response=>{
        if(response.data.type==='OPT_IN')dispatch({type:'set_following',following:true});
        else{dispatch({type:'set_following',following:false})}
      })
      .catch(error=>{
        dispatch({type:'set_following',following:false})
      })
  }

  useEffect(()=>{
    dispatch({type:'set_tag_view',tagView})
  },[tagView])

  useEffect(()=>{
    if(!state.basicData){
      fetchList();
    }
    if(!state.statsData){
      statsDataFetch();
    }
    window.removeEventListener('message',globalListenerRef.miniProfileEditListener)
    globalListenerRef.miniProfileEditListener = event=>{
      if(event.data.mini_profile_edit){
        dispatch({type:'set_editing',editing:true})
      }
      if(event.data.mini_profile_add_tag){
        setLinkModalOpen({
          linkableObjects:['TAG'],
          relations:['TAGGED_BY'],
          onLinkUpdated:collectionInstancesFetch
        })
      }
      if(event.data.mini_profile_link_collection){
        setLinkModalOpen({
          linkableObjects:['COLLECTION_INSTANCE'],
          relations:['MEMBER_OF'],
          onLinkUpdated:collectionInstancesFetch
        })
      }
      if(event.data.mini_profile_add_note){
        dispatch({type:'set_note_modal_open',noteModalOpen:true})
      }
      if(event.data.mini_profile_add_question){
        dispatch({type:'set_note_modal_open',noteModalOpen:{isQuestion:true}})
      }
    }
    window.addEventListener("message", globalListenerRef.miniProfileEditListener);
    return ()=>{
      window.removeEventListener('message',globalListenerRef.miniProfileEditListener)
    }
  // eslint-disable-next-line
  },[])

  useEffect(()=>{
    if(state.basicData){
      collectionInstancesFetch();
      followDataFetch();
    }
  // eslint-disable-next-line
  },[state.basicData])


  useEffect(()=>{
    window.removeEventListener('message',globalListenerRef.miniProfileLinkModalListener)
    if(!isLineageLinkable || !state.basicData)return;
    globalListenerRef.miniProfileLinkModalListener = (msg) => {
      if(msg.data.open_mini_profile_link_modal ){
        let relationship = ['SOURCE_OF','SOURCE_FROM'];
        if(state.basicData.object_type_txt==='TABLE')relationship.push('REPLACED_BY','REPLACES')

        let linkableObjects;
        if(msg.data.level!==undefined){
          linkableObjects = getLinkableObjectsByLevel({type:mapSearchObjectName(state.basicData.object_type_txt, state.basicData.code_type_txt), level:msg.data.level});
        }else{
          linkableObjects = lineageObjectsMap[mapSearchObjectName(state.basicData.object_type_txt, state.basicData.code_type_txt)]||[]
        }

        setLinkModalOpen({
          relations:relationship,
          linkableObjects,
          enableFilterHistory:true
        })
      }
    }
    window.addEventListener('message',globalListenerRef.miniProfileLinkModalListener)

    return ()=>{
      window.removeEventListener('message',globalListenerRef.miniProfileLinkModalListener)
    }

  // eslint-disable-next-line
  },[isLineageLinkable, state.basicData])

  const checkIsStewardOrOwner = () => {
    axiosCerebrum
      .get(`/api/users/${sessionData.id}/related`,{params:{
        object_id:id.toLowerCase(),
        relationship:'OWNER_OF,STEWARD_OF'
      }}) 
      .then(response=>{
        dispatch({type:'set_is_steward_or_owner', isStewardOrOwner:response.data.total>0})
      })
      .catch(error=>{
        console.log(error)
        dispatch({type:'set_is_steward_or_owner', isStewardOrOwner:false})
      })
  }

  useEffect(()=>{
    checkIsStewardOrOwner()
   // eslint-disable-next-line
  },[state.insightsData])
  
  if (state.basicDataLoading) {
    return (
      <div style={{ textAlign:'center', width: '18.75rem',margin:'80px auto'}}>
        <Typography className={classes.normalText}>Loading</Typography>
        <LinearProgress style={{ marginTop: '1.5rem' }} color="secondary" />
      </div>
    )
  }

  if (state.basicDataError ) {
    return (
      <DeadEnd variant="narrow"/>
    )
  }

  if(!state.basicData){
    return <div></div>
  }

  let buttons = [];

  if(customButtons)buttons.push(...customButtons)

  const getChildTypeName = () => {
    switch(state.basicData.object_type_txt){
      case 'TABLE':
        return 'COLUMN'
      case 'REPORT':
        return 'SHEET'
      case 'DATASET':
        return 'DATASET_TABLE'
      case 'DATASET_TABLE':
        return 'DATASET_FIELD'
      case 'DATA_PIPELINE':
        return 'DATA_PIPELINE'
      case 'CONTENT_APP':
        return 'REPORT'
      case 'SCHEMA':
        return 'TABLE'
      case 'SOURCE':
        return 'ASSET'
      default:
        return ''
    }
  }

  let tabOptions = [{name:'DETAILS',iconLabel:'DETAIL'}]

  if(['TABLE','REPORT','DATASET','DATASET_TABLE','DATA_PIPELINE','CONTENT_APP','SCHEMA','SOURCE'].includes(state.basicData.object_type_txt)){
    if(state.basicData.object_type_txt==='DATA_PIPELINE'){
      if(state.basicData.child_object_count_srt)tabOptions.push(getChildTypeName().replace(/_/g,' ')+'S')
    }else{
      tabOptions.push({name:getChildTypeName().replace(/_/g,' ')+'S',iconLabel:getChildTypeName()})
    }
  }
  if(['COLLECTION_INSTANCE'].includes(state.basicData.object_type_txt)){
    tabOptions.push({name:'RELATED',iconLabel:'related'})
  }
  if(checkCanAddKnowledge(state.basicData.object_type_txt)){
    tabOptions.push({name:'KNOWLEDGE',iconLabel:'note'})
  }
  if(state.basicData.object_type_txt==='SOURCE'){
    tabOptions.push({name:'UPSTREAM',iconLabel:'lineage_upstream'}, {name:'DOWNSTREAM',iconLabel:'lineage_downstream'})
  }

  return (
    <div 
      id={`mini-profile-${state.basicData.id}`} 
      className={classes.customScroll} 
      onScroll={event=>{
        if(event.target.id!==`mini-profile-${state.basicData.id}`)return;
        setScrollYOffest(event.target.scrollTop)
      }}
      ref={scrollContainerRef}
      style={{flexGrow:1,flexShrink:1,overflow:"auto",paddingBottom:16,paddingLeft:24,marginTop:8,width:'100%',boxSizing:'border-box'}}
    >
      {
        state.basicData && 
        <GenericLinkModal
          object={state.basicData}
          history={history}
          modalOpen={linkModalOpen}
          setModalOpen={setLinkModalOpen}
          linkableObjects={linkModalOpen.linkableObjects}
          relations={linkModalOpen.relations}
          onLinkUpdated={linkModalOpen.onLinkUpdated}
          profileDispatch={dispatch}
          enableFilterHistory={linkModalOpen.enableFilterHistory}
        />
      }

      {
        state.basicData && 
        <NoteAdder
          history={history}
          object={state.basicData}
          state={state}
          dispatch={dispatch}
          modalOpen={state.noteModalOpen}
          setModalOpen={value=>dispatch({type:'set_note_modal_open',noteModalOpen:value})}
          modalOnly={true}
          onSaveNote={()=>sendMessage({[`load-note-${state.basicData.id}`]:true})}
        />
      }
      <div style={{position:'sticky',top:0,background:theme.palette.background.main,zIndex:11,paddingBottom:16}}>
        <MiniProfileHeader
          data={state.basicData}
          history={history}
          state={state}
          dispatch={dispatch}
          linkedInstanceFetch={collectionInstancesFetch}
          linkedInstances={state.collectionInstancesData?state.collectionInstancesData.items:[]}
          buttons={buttons}
          sessionData={sessionData}
          onClickAddTag={()=>{
            setLinkModalOpen({
              linkableObjects:['COLLECTION_INSTANCE'],
              relations:['MEMBER_OF'],
              onLinkUpdated:collectionInstancesFetch
            })
          }}
        />
      </div>
      {
        generateCustomBody?
        generateCustomBody({scrollYOffset, scrollContainerRef}): 
        <div  
          className={classes.customScroll} 
          style={{display:'flex',overflow:'hidden'}}
        >
          <div style={{marginRight:16,flexGrow:1,flexShrink:1,overflow:'hidden'}}>
            <Body
              state={state}
              scrollYOffset={scrollYOffset}
              dispatch={dispatch}
              sessionData={sessionData}
              history={history}
              fetchList={fetchList}
              childTypeName={getChildTypeName()}
              tabOptions={tabOptions}
              tagView={tagView}
            />
          </div>

          <div style={{width:144,flexGrow:0,flexShrink:0,transform:`translate(0px,${scrollYOffset}px)`}}>
            <MiniVerticalTabBar
              tabOptions={tabOptions}
              tabState={state.tabState}
              setTabState={value => dispatch({ type: 'set_tab_state', tabState: value })}
              width={144}
              position='right'
            />
          </div>
        </div>
      }
    </div>
  )
}

const mapStateToProps = state => {
  return {
    pageCache: state.pageCache.pageCache,
    permissions: state.auth.permissions
  };
}

const mapDispatchToProps = dispatch => {
  return {
    storePageCache: (state) => dispatch(actions.storePageCache(state))
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(withTheme()(withStyles(styles)(MiniProfile)));